import { Utils } from './utils';
import { ModalDispatcher } from './components/modalDispatcher';
import { Swiper, Navigation, Scrollbar, Mousewheel } from 'swiper/dist/js/swiper.esm.js';
import anime from 'animejs/lib/anime.es.js';
import SimpleBar from 'simplebar';

Swiper.use([Swiper, Navigation, Scrollbar, Mousewheel]);


document.addEventListener('DOMContentLoaded', () => {

	let getRates;

	if (sessionStorage.getItem('currencies')) {
		getRates = new Promise(r => {
			r();
		})
	}
	else {
		getRates = fetch('https://www.cbr-xml-daily.ru/latest.js').then(r => r.json()).then(r => {
			sessionStorage.setItem('currencies', JSON.stringify(r))
			return new Promise(r => r())
		})
	}

	getRates.then(_ => {
		const currencies = JSON.parse(sessionStorage.getItem('currencies'));
		document.querySelectorAll('[data-currency]').forEach(el => {
			const curr = el.dataset.currency;
			const sign = curr === 'RUB' ? '$' : '₽';
			const rate = curr === 'RUB' ? (currencies.rates.USD).toFixed(2): (1 / currencies.rates[curr]).toFixed(2)
			el.innerText = `${rate} ${sign}`
		})
	});

	

	document.body.classList.add('is-loaded');

	let elems = document.querySelectorAll('[data-anim]');

	function intersectionHandler(entries) {
	  [].forEach.call(entries, function(entry) {
		 if (entry.isIntersecting) {
			entry.target.classList.add('is-visible')
		 }
		 else {
			entry.target.classList.remove('is-visible')
		 }

	  })
	}

	let observer = new IntersectionObserver(intersectionHandler);

	[].forEach.call(elems, function(entry) {
	  observer.observe(entry);
	});


	function checkHeader() {

		let scrollPosition = Math.round(window.scrollY);
		if (scrollPosition > 100){
			document.body.classList.add('sticky');
		} else {
			document.body.classList.remove('sticky');
		}
	}
	window.addEventListener('scroll', Utils.throttle(checkHeader, 25));


	let simpleBar = document.querySelectorAll('.simple_bar');
	[].forEach.call(simpleBar, function(bar) {
		new SimpleBar(bar);
	});

	let buttonBackward = document.querySelector('.auction_backward')
	if (buttonBackward) {
		buttonBackward.addEventListener('click', function() {
			history.back();
		});
	}
	

	[].map.call(document.querySelectorAll('[anim="ripple"]'), el=> {
		el.addEventListener('click',e => {
			e = e.touches ? e.touches[0] : e;
			const r = el.getBoundingClientRect(), d = Math.sqrt(Math.pow(r.width,2)+Math.pow(r.height,2)) * 2;
			el.style.cssText = `--s: 0; --o: 1;`;  el.offsetTop; 
			el.style.cssText = `--t: 1; --o: 0; --d: ${d}; --x:${e.clientX - r.left}; --y:${e.clientY - r.top};`
		})
	});
	

	new Swiper('.tabs_slider', {
		slidesPerView: 4,
		speed: 700,
		freeMode: true,
		spaceBetween: 15,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchStartPreventDefault: false,
		watchOverflow: true,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		breakpoints: {
			500: {
				slidesPerView: 1,
				spaceBetween: 10,
			},
		}
	});

	new Swiper('.example_slider', {
		slidesPerView: 3,
		speed: 700,
		freeMode: true,
		spaceBetween: 15,
		mousewheelControl: true,
		freeModeMinimumVelocity: 0.02,
		mousewheelReleaseOnEdges: false,
		touchStartPreventDefault: false,
		slidesOffsetAfter: -350,
		mousewheel: {
			invert: true,
			enabled: true,
			forceToAxis: true
		},
		navigation: {
			nextEl: '.example_button--next',
			prevEl: '.example_button--prev',
		},
		scrollbar: {
			el: '.example_slider_scrollbar',
			draggable: true,
		},
		breakpoints: {
			500: {
				slidesOffsetAfter: 0,
				slidesPerView: 1,
				spaceBetween: 10,
			},
			1200: {
				slidesOffsetAfter: -100,
			},
			1300: {
				slidesOffsetAfter: -200,
			},
			1400: {
				slidesOffsetAfter: -300,
			},
		}
	});


	let inputsForm = document.querySelectorAll('.form_input__input');
	[].forEach.call(inputsForm, function(input) {

		input.addEventListener('input', function(event) {
			if (input.validity.valid) {
				input.closest('.form_input').classList.remove('invalid');
			}
			else {
				input.closest('.form_input').classList.add('invalid');
			}

			setTimeout(() => {	
				if(input.value) {
					input.classList.add('is-filled');
				} else {
					input.classList.remove('is-filled');
				}
			})

		});
	});



	let Dropdown = (function() {

		function open(event) {

			let el = event.target.closest('.example_slide__more');
			let slide = event.target.closest('.swiper-slide');

			if (el) {
				slide.classList.toggle('drop-active');
			} else if (!el) {

				let moreButtons = document.querySelectorAll('.example_slide__more');
				[].forEach.call(moreButtons, function(button) {
					button.closest('.swiper-slide').classList.remove('drop-active');
				})

			}

		}
		function bindEvents() {

			document.addEventListener('click', open);


		}

		function init() {
			bindEvents();
		}
		return {
			init: init
		}

	})();

	var phoneElem = document.querySelectorAll('[name="phone"]'),
	phoneMasks = [];
	[].forEach.call(phoneElem, function(el, i) {
		phoneMasks[i] = new IMask(el, {
			mask: '+{7} (0',
			placeholderChar: ''
		});
		el.addEventListener('focus', function() {
			phoneMasks[i].updateOptions({ lazy: false })
		})
		phoneMasks[i].on('accept', function() {
			var mask = (phoneMasks[i]._unmaskedValue === '7') ? '+{7} (0' : '+{7} (000) 000-00-00',
			placeholderChar = (phoneMasks[i]._unmaskedValue === '7') ? '' : '_';
			phoneMasks[i].updateOptions({ mask: mask, placeholderChar: placeholderChar })
		})
		el.addEventListener('blur', function() {
			if (phoneMasks[i]._unmaskedValue.length < 11) {
				el.value = '';
				phoneMasks[i].updateOptions({ lazy: true })
				phoneMasks[i].value = '';
			}

		})

	});

	let toggleSlider = (function() {

		function watchEvent(event) {
			let el = event.target.closest('[data-linked-slider]');
		
			if (!el) return;
			
			document.querySelector('.example_tabs__tab.is-clicked').classList.remove('is-clicked');
			el.classList.add('is-clicked');

			let date = el.dataset.linkedSlider;
			let sliders = document.querySelectorAll('.example_slider');
			let dateSlider = [].find.call(sliders, el => el.dataset.slider === date);

			document.querySelector('.example_slider.is-active').classList.remove('is-active');
			dateSlider.classList.add('is-active');

		}

		function bindEvents() {

			document.addEventListener('click', watchEvent);


		}

		function init() {
			bindEvents();
		}
		return {
			init: init
		}

	})();




	let timeElements = document.querySelectorAll("[data-time]");
	
	function updateTime() {

		let today = new Date(),
			 hours = today.getHours(),
			 minutes = today.getMinutes(),
			 sec = today.getSeconds(),
			 time = '';

		timeElements.forEach(el => {
			let minutesStr = minutes < 10 ? '0' + minutes : minutes;
			
			el.innerHTML = '';

			let moskovTime = hours + 7 + ':' + minutesStr,
				 tokyoTime = hours - 1 + ':' + minutesStr,
				 vladivostokTime = hours + ':' + minutesStr;

			if (el.dataset.time === 'moskov') {
				el.innerHTML = moskovTime;
			}
			if (el.dataset.time === 'tokyo') {
				el.innerHTML = tokyoTime;
			}
			if (el.dataset.time === 'vladivostok') {
				el.innerHTML = vladivostokTime;
			}
			console.log(moskovTime);
			

			

		})
	};

	updateTime();
	setInterval(updateTime, 10000);




	Dropdown.init();
	toggleSlider.init();
	ModalDispatcher.init();


	window.addEventListener('submit', function(event) {
		event.preventDefault();
		var form = event.target.closest('form');
		form.closest('.modal') && form.closest('.modal').classList.add('on-request');
		var data = new FormData(form);
		 fetch("/mailer.php", {
			method: 'POST',
			body: data
		}).then(function(r) {
	
			return r.text();
	
		}).then(function(){
			form.closest('.modal') && form.closest('.modal').classList.remove('on-request');
			document.dispatchEvent(new CustomEvent('needCloseModal', {detail: 'feedback'}));
			document.dispatchEvent(new CustomEvent('needModal', {detail: 'success'}));
	
		})
	})
	
	console.log(`%c
												
												
												
												
												
												
				*****           ,****..,  		
			   ******         (   /**/   		
			  ********                   		
			 ****  ***,      ****    ,***		
			,***   ****     (***#    ****		
			****    ****    (***     ****		
		   *************,   (***     ****		
		  ,**************    ***     ****		
		  ****        ****   *****,******		
		 ****         ****    ****   ****		
												
												
												
												
												
												`		
	
	
	, 'color: white; background: #005ED8; font-weight: bold');
	console.log('%c 👉 https://i-to.me', 'color: #0071FF; font-weight: bold; font-size: 1.5rem')


})

